<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class='table-page-search-wrapper'>
        <a-form layout='inline'>
          <a-row :gutter='48'>

            <a-col :md='4' :sm='24'>
              <a-form-item label="显示位置" prop="useFlag">
                <a-select v-model="queryParam.bannerType" placeholder="请选择显示位置" style="width: 100%" allow-clear>
                  <a-select-option :value="1">首页</a-select-option>
<!--                  <a-select-option :value="2">消息</a-select-option>-->
<!--                  <a-select-option :value="3">分享海报</a-select-option>-->
                </a-select>

              </a-form-item>
            </a-col>
            <a-col :md='8' :sm='24'>
              <span class='table-page-search-submitButtons'
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type='primary' @click='handleQuery'><a-icon type='search' />查询</a-button>
                <a-button style='margin-left: 8px' @click='resetQuery'><a-icon type='redo' />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()">
          <a-icon type="plus" />新增
        </a-button>

        <a-button type="danger" :disabled="multiple" @click="handleDelete" >
          <a-icon type="delete" />删除
        </a-button>

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="isOpen" slot-scope="text, record">
          <a-switch checkedChildren="是" unCheckedChildren="否" v-model="record.isOpen == 1" @change="changeIsOpen($event,record)"/>
        </span>
        <!--        <img slot="bannerUsl" slot-scope="text, record" style="width: 48px;height: 48px;"  v-image-preview :src="record.bannerUsl">-->
        <img style="width: 48px;height: 48px;" v-image-preview :src="record.bannerUrl" slot="bannerUrl" slot-scope="text, record">

        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" >
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
        :rowKey="(record, index) => index + 1"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageBanner, listBanner, delBanner, changeIsOpen } from '@/api/banner/banner'
import CreateForm from '@/views/banner/modules/CreateForm.vue'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Banner',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        sort: null,
        videoId: null,
        isJump: null,
        bannerUrl: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'id',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '序号',
          ellipsis: true,
          customRender: (text, record, index) => index + 1,
          align: 'center'
        },

        {
          title: '位置',
          dataIndex: 'bannerType',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 1) {
              return '首页'
            }else if(t == 2) {
              return '消息'
            }else if(t == 3) {
              return '分享海报'
            }
          }
        },

        {
          title: '轮播图',
          dataIndex: 'bannerUrl',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'bannerUrl' }
        },
        {
          title: '跳转类型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0) {
              return '不跳转'
            }else if(t == 1) {
              return '图文详情'
            }else if(t == 2) {
              return '外链'
            }else if(t == 3) {
              return '用户主页'
            }
          }
        },
        {
          title: '是否启用',
          dataIndex: 'isOpen',
          align: 'center',
          scopedSlots: { customRender: 'isOpen' },
        },

        {
          title: '排序',
          dataIndex: 'sort',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询轮播图列表 */
    getList () {
      this.loading = true
      pageBanner(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    changeIsOpen (e,record) {
      record.isOpen = e ? 1 : 0
      changeIsOpen(record).then(response => {
        this.$message.success(
          '操作成功'
        )
        return  record.isOpen = e ? 1 : 0
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        sort: undefined,
        videoId: undefined,
        isJump: undefined,
        bannerUrl: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delBanner(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('banner/banner/export', {
            ...that.queryParam
          }, `轮播图_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
